



















































































































































































































































































































































































import { Vue, Component } from 'vue-property-decorator';

import { appName } from '@/env';
import { readDashboardMiniDrawer, readDashboardShowDrawer, readHasAdminAccess } from '@/store/main/getters';
import { commitSetDashboardShowDrawer, commitSetDashboardMiniDrawer } from '@/store/main/mutations';
import { dispatchUserLogOut } from '@/store/main/actions';
import { readIsLoggedIn } from '@/store/main/getters';


@Component
export default class Main extends Vue {
  public drawer = null;
  public menu = null;

  get loggedIn() {
    return readIsLoggedIn(this.$store);
  }

  public async logout() {
    await dispatchUserLogOut(this.$store);
  }

  public get hasAdminAccess() {
    return readHasAdminAccess(this.$store);
  }  

  // adaptive screen size
  public isMobile = false;

  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize);
    }
  }

  created() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  onResize() {
    this.isMobile = window.innerWidth < 960;
  }
}
// export default {
//   props: {
//     isFluid: {
//       type: Boolean,
//       default: false
//     }
//   },
//   data() {
//     return {
//       drawer: null,
//       menu: null
//     }
//   }
// }
