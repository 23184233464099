<template>
  <v-layout>
    <v-main>
      <div>
        <ToolBarNew />
        <router-view></router-view>
        <v-footer color="surface">
          <v-container>
            <v-img class="rounded-lg mt-4 mb-4" max-height="50" contain :src="require('@/assets/images/rabbit_helpers_logo_word.png')" alt="" />
            <v-flex v-show="false">
              <div class="d-flex flex-wrap align-center justify-center font-weight-bold body-2">
                <router-link to="/news" class="text-decoration-none mx-2 mb-1">最新消息</router-link>
                <router-link to="/plan" class="text-decoration-none mx-2 mb-1">方案</router-link>
                <router-link to="/about" class="text-decoration-none mx-2 mb-1">關於兔子</router-link>
                <!-- <a href="https://docs.lepus.fi/" target="_blank" class="text-decoration-none mx-2 mb-1">關於兔子</a> -->
                <router-link to="/contact" class="text-decoration-none mx-2 mb-1">聯繫我們</router-link>
              </div>
              <div class="d-flex flex-wrap align-center justify-center body-2 pt-2">
                <!-- <router-link to="/news" class="text-decoration-none mx-2 mb-1" style="color:black;">最新消息</router-link> -->
                <a href="https://docs.lepus.fi/about/price" target="_blank" class="text-decoration-none mx-2 mb-1 secondary--text">費用</a>
                <a href="https://docs.lepus.fi/risk/risks" target="_blank" class="text-decoration-none mx-2 mb-1 secondary--text">風險</a>
                <a href="https://docs.lepus.fi/others/public-info" target="_blank" class="text-decoration-none mx-2 mb-1 secondary--text">公開數據</a>
                <a href="https://docs.lepus.fi/faq/faq" target="_blank" class="text-decoration-none mx-2 mb-1 secondary--text">常見問題</a>
              </div>
              <!-- <v-img class="rounded-lg mt-2" max-height="60" contain :src="require('@/assets/images/rabbit_helpers_logo_horizontal.png')" alt="" /> -->
            </v-flex>

            <div class="d-flex align-center justify-center mt-2">
              <v-btn icon color="secondary lighten-2" class="mx-1" href="https://www.facebook.com/LepusFinance/" target="_blank"><v-icon>mdi-facebook</v-icon></v-btn>
              <!-- <v-btn icon color="secondary lighten-2" class="mx-1"><v-icon>mdi-twitter</v-icon></v-btn>
              <v-btn icon color="secondary lighten-2" class="mx-1"><v-icon>mdi-linkedin</v-icon></v-btn>
              <v-btn icon color="secondary lighten-2" class="mx-1"><v-icon>mdi-google-plus</v-icon></v-btn> -->
            </div>
            <div class="d-flex flex-column flex-sm-row align-center justify-center mt-3 caption">
              <div class="secondary--text">客服信箱：support@lepus.fi</div>
            </div>
            <div class="d-flex flex-column flex-sm-row align-center justify-center mt-4 overline">
              <div class="secondary--text">Redefine Crypto Investing</div>
            </div>
            <div class="d-flex flex-column flex-sm-row align-center justify-center mt-2 overline">
              <div class="secondary--text">Lepus Finance 2020-2024</div>
            </div>
          </v-container>
        </v-footer>
      </div>
    </v-main>
  </v-layout>
</template>

<script>
import ToolBarNew from './ToolBar2.vue'

export default {
  components: {
    ToolBarNew
  }
}
</script>
